<template>
    <div class="con-wrap">
        <CarrotTitle title="전체 통계">
            - 통계는 재직자 기준입니다.(휴직자, 육아휴직자, 퇴사자, 기타 항목 제외)<br>
            - 기타 항목은 MI, 고문, 운영매니저 수 입니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <div class="mt-40 mb-20">
                        <select name="syear" id="syear" class="w-100px" v-model="view.syear" @change="view.makeSearchMonths">
                            <option :value="v" v-for="(v, k) in view.search_years" :key="k">{{ v }}</option>
                        </select>
                        <select name="smonth" id="smonth" class="w-100px ml-5" v-model="view.smonth" @change="view.doSearch">
                            <option value="">선택</option>
                            <option :value="v" v-for="(v, k) in view.search_months" :key="k">{{ v }} 월</option>
                        </select>
                        <div class="clear"></div>
                    </div>
                    
                    <div class="dp-table w-80per mt-50">
                        <div class="float-left w-70per pr-25">
                            <table class="table-col">
                                <colgroup>
                                    <col span="5">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>전체<br>(재직자+휴직자+육아휴직자)</th>
                                        <th>재직자<br>(Probation 포함)</th>
                                        <th>Probation</th>
                                        <th>휴직자</th>
                                        <th>육아휴직자</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ view.info.total }}</td>
                                        <td>{{ view.info.incumbent }}</td>
                                        <td>{{ view.info.probation }}</td>
                                        <td>{{ view.info.leave }}</td>
                                        <td>{{ view.info.babyleave }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="float-right w-30per pl-25">
                            <table class="table-col">
                                <colgroup>
                                    <col span="2">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>퇴사자</th>
                                        <th>기타</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ view.info.retiree }}</td>
                                        <td>{{ view.info.etc }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <table class="table-col mt-50 w-70per">
                        <colgroup>
                          <col :span="view.sector.length">
                        </colgroup>
                        <thead>
                          <tr>
                             <th :colspan="view.sector.length">부문 별 (재직자)</th>
                           </tr>
                          <tr>
                             <th v-for="(irow, i) in view.sector" :key="i">{{ irow.deptname }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                             <td v-for="(irow, i) in view.sector" :key="i">{{ irow.cnt }}</td>
                          </tr>
                        </tbody>
                    </table>
                    <table class="table-col mt-50 w-70per">
                        <colgroup>
                            <col :span="view.corp.length">
                        </colgroup>
                        <thead>
                            <tr>
                                <th :colspan="view.corp.length">법인 별 (재직자)</th>
                            </tr>
                            <tr>
                                <th v-for="(irow, i) in view.corp" :key="i">{{ irow.kname }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td v-for="(irow, i) in view.corp" :key="i">{{ irow.cnt_total }}</td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <table class="table-col mt-50">
                        <colgroup>
                            <col span="11">
                        </colgroup>
                        <thead>
                            <tr>
                                <th colspan="11">본부 별 (재직자)</th>
                            </tr>
                            <tr>
                                <th>글로벌교육사업본부</th>
                                <th>스마트러닝</th>
                                <th>경영기획실</th>
                                <th>이러닝융합서비스</th>
                                <th>글로벌역량개발연구소</th>
                                <th>밝음</th>
                                <th>베어드</th>
                                <th>한남센터</th>
                                <th>글로벌교육사업본부 센터</th>
                                <th>E-Post센터</th>
                                <th>대련센터</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ view.office.gbiz }}</td>
                                <td>{{ view.office.smart }}</td>
                                <td>{{ view.office.mplan }}</td>
                                <td>{{ view.office.econv }}</td>
                                <td>{{ view.office.gcmpt }}</td>
                                <td>{{ view.office.bright }}</td>
                                <td>{{ view.office.baird }}</td>
                                <td>{{ view.office.hannam }}</td>
                                <td>{{ view.office.gedu }}</td>
                                <td>{{ view.office.epost }}</td>
                                <td>{{ view.office.dalian }}</td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <table class="table-col mt-50">
                        <colgroup>
                            <col :span="(view.gbiz.length+1)">
                        </colgroup>
                        <thead>
                            <tr>
                                <th :colspan="(view.gbiz.length+1)">글로벌교육사업본부 (재직자)</th>
                            </tr>
                            <tr>
                                <th>대표이사</th>
                                <th v-for="(irow, i) in view.gbiz" :key="i">{{ irow.kname }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ view.gbiz_part }}</td>
                                <td v-for="(irow, i) in view.gbiz" :key="i">{{ irow.cnt }}</td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <table class="table-col mt-50">
                        <colgroup>
                            <col span="5">
                        </colgroup>
                        <thead>
                            <tr>
                                <th :colspan="view.smart.length">스마트러닝사업본부 (재직자)</th>
                            </tr>
                            <tr>
                                <th v-for="(irow, i) in view.smart" :key="i">{{ irow.kname }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td v-for="(irow, i) in view.smart" :key="i">{{ irow.cnt }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table class="table-col mt-50">
                        <colgroup>
                            <col width="200">
                            <col span="7">
                        </colgroup>
                        <thead>
                            <tr>
                                <th rowspan="2">부문</th>
                                <th colspan="7">연차 별 (재직자)</th>
                            </tr>
                            <tr>
                                <th>1년 미만</th>
                                <th>1~3년</th>
                                <th>3~5년</th>
                                <th>5~7년</th>
                                <th>7~10년</th>
                                <th>10~15년</th>
                                <th>15년 이상</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in view.sectorList" :key="i" :class="{ 'total':irow.is_total=='Y' }">
                                <th>{{ irow.deptname }}</th>
                                <td>{{ irow.cnt_0y }}</td>
                                <td>{{ irow.cnt_1y }}</td>
                                <td>{{ irow.cnt_3y }}</td>
                                <td>{{ irow.cnt_5y }}</td>
                                <td>{{ irow.cnt_7y }}</td>
                                <td>{{ irow.cnt_10y }}</td>
                                <td>{{ irow.cnt_15y }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table class="table-col mt-50">
                        <colgroup>
                            <col width="200">
                            <col span="6">
                        </colgroup>
                        <thead>
                            <tr>
                                <th rowspan="2">부문</th>
                                <th colspan="6">직급</th>
                            </tr>
                            <tr>
                                <th>매니저</th>
                                <th>Expert매니저</th>
                                <th>Professional 매니저</th>
                                <th>리드</th>
                                <th>대표이사</th>
                                <th>CEO</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in view.position" :key="i" :class="{ 'total':irow.is_total=='Y' }">
                                <th>{{ irow.deptname }}</th>
                                <td>{{ irow.cnt_manager }}</td>
                                <td>{{ irow.cnt_expert }}</td>
                                <td>{{ irow.cnt_prof }}</td>
                                <td>{{ irow.cnt_lead }}</td>
                                <td>{{ irow.cnt_part }}</td>
                                <td>{{ irow.cnt_ceo }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table class="table-col mt-50">
                        <colgroup>
                            <col width="200">
                            <col span="10">
                        </colgroup>
                        <thead>
                            <tr>
                                <th rowspan="2">부문</th>
                                <th colspan="4">연령</th>
                                <th colspan="2">혼인</th>
                                <th colspan="2">국적</th>
                                <th colspan="2">성별</th>
                            </tr>
                            <tr>
                                <th>20~29</th>
                                <th>30~39</th>
                                <th>40~49</th>
                                <th>50~</th>
                                <th>미혼</th>
                                <th>기혼</th>
                                <th>내국인</th>
                                <th>외국인</th>
                                <th>여성</th>
                                <th>남성</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in view.sector" :key="i" :class="{ 'total':irow.is_total=='Y' }">
                                <th>{{ irow.deptname }}</th>
                                <td>{{ irow.age_20 }}</td>
                                <td>{{ irow.age_30 }}</td>
                                <td>{{ irow.age_40 }}</td>
                                <td>{{ irow.age_50 }}</td>
                                <td>{{ irow.married_n }}</td>
                                <td>{{ irow.married_y }}</td>
                                <td>{{ irow.foreigner_n }}</td>
                                <td>{{ irow.foreigner_y }}</td>
                                <td>{{ irow.gender_f }}</td>
                                <td>{{ irow.gender_m }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <router-link to="IncumbentManagementList"><button class="btn-default float-left mt-30">목록</button></router-link>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import axios from '@/plugins/axios.js'


export default {
    layout:"myPIMS",
    components: {
    },
    setup() {
        const router = new useRouter();

        const view = reactive({
            search_years : [],
            syear  : "",
            smonth : "",

            info      : {},
            corp      : { cg:0, cs:0, br:0, ce:0, sb:0 },
            sectorList: {},
            sector    : {},
            office    : {},
            gbiz      : {},
            smart     : {},
            position  : {},
            etc       : {},

            doSearch : () => {
                let params = {
                    syear  : view.syear,
                    smonth : view.smonth
                };

                axios.get("/rest/mypims/getOverall", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.info      = res.data.info;
                        view.corp      = res.data.corp;
                        view.sectorList = res.data.sectorList;
                        view.sector      = res.data.sector;
                        view.office    = res.data.office;
                        view.gbiz      = res.data.gbiz;
                        view.smart     = res.data.smart;
                        view.position  = res.data.position;
                        view.etc       = res.data.etc;

                        view.gbiz_part = res.data.gbiz_part;
                    } else {
                        console.log(res.data.err_msg);
                    }
                });
            },

            goList : () => {
                router.push({
                    name : "myPIMS-IncumbentManagementList"
                });
            },

            makeSearchYears: () => {
                let ey = "2021";
                let cd = new Date();
                let cy = cd.getFullYear();
                for(let i=cy; i>=ey; i--){
                    view.search_years.push(i);
                }
            },

            makeSearchMonths: () => {
                let cd = new Date();
                let cy = cd.getFullYear();
                view.search_months = [ '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12' ];
                if( Number(view.syear) >= cy ){
                    view.search_months.splice(cd.getMonth()+1, 12-cd.getMonth());
                }
                view.smonth = "";
            }
        });
        
        onMounted(() => {
            // Mounted
            view.doSearch();
            view.makeSearchYears();
            view.makeSearchMonths();

            let td = new Date();
            view.syear  = td.getFullYear();
            view.smonth = td.getMonth()<9?'0'+(td.getMonth()+1):td.getMonth()+1;

            console.log(view);
        });

        onUnmounted(() => { 
            // UnMounted
        });

        return {view};
    }
}
</script>

<style lang="scss" scoped>
</style>